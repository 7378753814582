export enum DcPlan {
  Trial = "Trial",
  Essential = "Essential",
  Pro = "Pro",
  Enterprise = "Enterprise",
}

export enum DcPlanCycle {
  Monthly,
  Annual,
}

export interface DcPricing {
  plans: DcPricingPlan[];
  note: string;
  trial: {
    title: string;
    description: string;
    price: string;
    features: string[];
    button: {
      label: string;
      click: () => void;
    };
  };
}

export interface DcPricingPlan {
  id: DcPlan;
  title: string;
  description?: string;
  price: string;
  discountedPrice?: string;
  yearlyPrice: string;
  discountedYearlyPrice?: string;
  cycle: string;
  highlight: boolean;
  badge?: string;
  features: Array<{
    name: string;
    tooltip?: string;
  }>;
  button: {
    label: string;
    click: () => void;
  };
}
